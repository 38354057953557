/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/**
 * 
 */





/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

    /**
     * colors
     */
  
    --rich-black-fogra-29-1: hsl(214, 32%, 15%);
    --rich-black-fogra-29-2: hsl(230, 14%, 8%);
    --rich-black-fogra-39: hsl(158, 29%, 5%);
    --granite-gray: hsl(0, 0%, 40%);
    --go-green_50: hsla(145, 63%, 42%, 0.5);
    --go-green_8: hsla(145, 63%, 42%, 0.08);
    --go-green_5: hsla(145, 63%, 42%, 0.05);
    --light-gray: hsl(0, 0%, 80%);
    --mint-cream: hsl(204, 100%, 98%);
    --cultured: hsl(0, 0%, 93%);
    --go-green: rgb(27, 92, 121);
    --white: hsl(0, 0%, 100%);
    --jet: hsl(0, 0%, 18%);
    
    /* rgb(46, 192, 187) */
    /**
     * typography
     */
  
    --ff-poppins: 'poppins', sans-serif;
    --ff-cuprum: 'Cuprum', sans-serif;
  
    --fs-1: 4.5rem;
    --fs-2: 3.6rem;
    --fs-3: 3.5rem;
    --fs-4: 3.2rem;
    --fs-5: 2.5rem;
    --fs-6: 2.4rem;
    --fs-7: 2.2rem;
    --fs-8: 2rem;
    --fs-9: 1.8rem;
    --fs-10: 1.5rem;
    --fs-11: 1.4rem;
    --fs-12: 1.3rem;
  
    --fw-600: 600;
    --fw-500: 500;
  
    /**
     * spacing
     */
  
    --section-padding: 50px;
  
    /**
     * shadow
     */
  
    --shadow-1: 3px 4px 30px hsla(0, 0%, 53%, 0.1);
    --shadow-2: 5px 3px 40px hsla(191, 100%, 17%, 0.1);
  
    /**
     * radius
     */
  
    --radius-5: 5px;
  
    /**
     * transition
     */
  
    --transition-1: 0.25s ease;
    --transition-2: 0.5s ease;
    --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);
  
  }
  
  
  
  /* MODAL CSS */
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.3);
    z-index: 1000; /* Set a higher z-index to keep the modal on top */
    max-width: 90%; /* Set maximum width */
    max-height: 90%; /* Set maximum height */
    overflow: auto; /* Enable scrolling if content exceeds the modal size */
  }
  
  .modal-content {
   /* padding: 1px 60px; */
text-align: center;

  }
  .modal-title{
      padding: 1px 80px;
   
  }
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
   
    /* Add styles for the close button */
  }
  
  /*-----------------------------------*\
    #RESET
  \*-----------------------------------*/
  
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  li { list-style: none; }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  a,
  img,
  span,
  input,
  button,
  ion-icon { display: block; }
  
  img { height: auto; 
  }
  
  input,
  button {
    background: none;
    border: none;
    font: inherit;
  }
  
  input { width: 100%; }
  
  button { cursor: pointer; }
  
  ion-icon { pointer-events: none; }
  
  address { font-style: normal; }
  
  html {
    font-family: var(--ff-poppins);
    font-size: 10px;
    scroll-behavior: smooth;
  }
  
  body {
    background-color: var(--white);
    color: var(--granite-gray);
    font-size: 1.6rem;
    line-height: 1.7;
  }
  
  :focus-visible { outline-offset: 4px; }
  
  ::-webkit-scrollbar { width: 10px; }
  
  ::-webkit-scrollbar-track { background-color: hsl(0, 0%, 98%); }
  
  ::-webkit-scrollbar-thumb { background-color: hsl(0, 0%, 80%); }
  
  ::-webkit-scrollbar-thumb:hover { background-color: hsl(0, 0%, 70%); }
  
  
  .sectorimg{
    opacity: 0.7 !important;
    /* transition: var(--transition-1); */
  }
  .sectorimg:hover{
    opacity: 1 !important;
  }
  
  
  /*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/
  
  /* .container { padding-inline: 10px; } */
  
  .section { 
    padding-block: var(--section-padding); 
  /* padding-top: 0px !important; */
  }
  
  .has-before,
  .has-after {
    position: relative;
    z-index: 1;
  }
  
  .has-before::before,
  .has-after::after {
    content: "";
    position: absolute;
  }
  
  .h1,
  .h2,
  .h3,
  .h2-sm {
    color: var(--rich-black-fogra-29-1);
    font-family: var(--ff-cuprum);
  }
  
  .h1 {
    font-size: var(--fs-1);
    line-height: 1.1;
  }
  
  .h2 { font-size: var(--fs-2); }
  
  .h2,
  .h3,
  .h2-sm { line-height: 1.3; }
  
  .h2-sm { font-size: var(--fs-4); }
  
  .h3 { font-size: var(--fs-7); }
  
  .btn-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  
  .btn {
    font-weight: var(--fw-600);
    text-transform: uppercase;
    padding: 12px 24px;
    border-radius: var(--radius-5);
    max-width: max-content;
    transition: var(--transition-1);
  }
  
  .btn-primary {
    background-color: var(--go-green);
    color: var(--white);
  }
  
  .btn-primary:is(:hover, :focus) { background-color: var(--rich-black-fogra-29-1); }
  
  .flex-btn {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .img-holder {
    aspect-ratio: var(--width) / var(--height);
    background-color: var(--light-gray);
  }
  
  .img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;

    opacity: 0.9;
  }
  .hero-img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
 
  }
  
  .w-100 { width: 100%; 

  }
  
  .text-center { text-align: center; }
  
  .section-subtitle {
    color: var(--go-green);
    font-weight: var(--fw-500);
  }
  
  .grid-list {
    display: grid;
    gap: 20px;
  }
  .btn-link-color{
    color: inherit !important;
  }
  .btn-link {
    color: var(--rich-black-fogra-29-1);
    font-size: var(--fs-12);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    transition: var(--transition-1);
  }
  

  
  
  /* -----------------------------------*\
    #HEADER
  \*-----------------------------------*/
  
.header .btn-outline { display: none; }
  
  .header {
    padding-block: 15px;
  
  }
  

  /* .header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */


  
  
  /*-----------------------------------*\
    #HERO
  \*-----------------------------------*/
  
  .hero {
    background-color: var(--mint-cream);
    padding-block-start: calc(var(--section-padding) + 50px);
    text-align: center;
    overflow: hidden;
  }
  
  .hero .container {
    display: grid;
    gap: 20px;
  }
  
  .hero-subtitle {
    color: var(--go-green);
    font-size: var(--fs-12);
    font-weight: var(--fw-500);
    max-width: max-content;
    margin-inline: auto;
    z-index: 0;
  }
  
  .hero-subtitle::before {
    top: 8px;
    left: -20px;
    width: 15px;
    height: 2px;
    background-color: var(--go-green);
  }
  
  .hero-title { margin-block: 10px 15px; }
  
  .hero-text { color:  var(--rich-black-fogra-29-1); }
  
  .hero .btn-group { margin-block-start: 40px; }
  
  .hero .btn-icon {
    background-color: var(--go-green);
    color: var(--white);
    /* font-size: 20px; */
    padding: 20px;
    border-radius: 50%;
    animation: pulse 2s ease infinite;
  }
  
  @keyframes pulse {
    0% { box-shadow: 0 0 0 0 var(--go-green); }
    100% { box-shadow: 0 0 0 20px transparent; }
  }
  
  .hero .flex-btn .span {
    color: var(--go-green);
    font-size: var(--fs-9);
    font-weight: var(--fw-600);
  }
  
  .hero-banner,
  .hero-banner > img { border-radius: 10%; }
  
  .hero-banner::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    height: 120%;
    background-image: url('./images/hero-pattern.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: -1;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #ABOUT
  \*-----------------------------------*/
  
  .about .container {
    display: grid;
    gap: 30px;

  }
  
  .about-banner { filter: drop-shadow(2px 2px 5px hsla(0, 0%, 0%, 0.08)); }
  
  .about .section-text { margin-block: 20px 13px; }
  
  .about-list { margin-block-end: 50px; }

  .about-list .has-before {
    font-family: var(--ff-cuprum);
    font-size: var(--fs-9);
    font-weight: var(--fw-600);
    padding-inline-start: 20px;
    line-height: 1.5;
  }

  .medalicon{
    color: var(--go-green);
    font-size: 40px;
    margin-top: 10px;
    /* position: absolute; */
  }
  .about-list .has-before:not(:last-child) { margin-block-end: 15px; }
  
  .about-list .has-before::before {
    top: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--go-green);
  }
  .dots::before{
    top: 4px !important;
    left: 0 !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    background-color: var(--go-green) !important;
  }
  .about .btn-group { justify-content: flex;
  
  }
  
  .about .btn-group .flex-btn { gap: 10px; }
  
  .about .btn-icon ion-icon {
    color: var(--go-green);
    font-size: 40px;
  }
  
  .about .btn-group .span {
    color: var(--go-green);
    font-family: var(--ff-cuprum);
    font-size: var(--fs-7);
    font-weight: var(--fw-600);
  }
  
  
  
  
  
  /*-----------------------------------*\
    #SERVICE
  \*-----------------------------------*/
  
  .service .section-text { margin-block: 15px 50px; }
  
  .service .grid-list { margin-block-end: 50px; }
  
  .service-card {
    height: 100%;
    padding: 30px 25px;
    text-align: center;
    box-shadow: var(--shadow-1);
  }
  
  .service-card::after {
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--go-green);
    transition: var(--transition-2);
  }
  
  .service-card:is(:hover, :focus-within)::after { width: 100%; }
  
  .service-card .card-icon {
    max-width: max-content;
    margin-inline: auto;
    margin-block-end: 10px; 
  }
  
  .service-card .img { transition: var(--transition-1); }
  
  .service-card:is(:hover, :focus-within) .img { transform: scale(1.1); }
  
  .service-card .card-text { margin-block: 5px 13px; }
  
  .service .btn { margin-inline: auto; }
  
  
  
  
  
  /*-----------------------------------*\
    #FEATURES
  \*-----------------------------------*/
  
  .features .section-text { margin-block: 15px 50px; }
  
  .features-card {
    position: relative;
    background-color: var(--white);
    padding: 25px;
    padding-block-start: 40px;
    margin-block-start: 24px;
    box-shadow: var(--shadow-1);
    transition: var(--transition-2);
  }
  
  .features-card:hover { background-color: var(--go-green); }
  
  .features-card .card-number {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    background-color: var(--cultured);
    font-size: var(--fs-7);
    font-weight: var(--fw-600);
    padding-block: 5px;
    min-width: 50px;
    text-align: center;
    border-radius: var(--radius-5);
    transition: var(--transition-1);
  }
  
  .features-card:hover .card-number { color: var(--go-green); }
  
  .features-card .card-title {
    font-size: var(--fs-7);
    margin-block-end: 5px;
  }
  
  .features-card :is(.card-title, .card-text) { transition: var(--transition-1); }
  
  .features-card:hover :is(.card-title, .card-text) { color: var(--white); }
  
  
  
  
  
  /*-----------------------------------*\
    #FAQ
  \*-----------------------------------*/
  
  .faq .container {
    display: grid;
    gap: 20px;
  }
  
  .faq .title-wrapper {
    background-color: var(--white);
    padding: 30px 25px;
    box-shadow: var(--shadow-1);
  }
  
  .faq .section-title {
    font-size: var(--fs-4);
    margin-block-end: 30px;
  }
  
  .faq-card {
    background-color: var(--go-green_5);
    border-radius: var(--radius-5);
    overflow: hidden;
  }
  
  .faq-card .card-action {
    --action-bg: var(--go-green_8);
    --color: var(--granite-gray);
  
    background-color: var(--action-bg);
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    gap: 20px;
    transition: var(--transition-1);
  }
  
  .faq-card .card-action:is(:hover, :focus, .active) {
    --action-bg: var(--go-green);
    --color: var(--white);
  }
  
  .faq-card .card-title {
    color: var(--color);
    font-size: var(--fs-8);
    transition: var(--transition-1);
  }
  
  .faq-card ion-icon {
    color: var(--color);
    font-size: 15px;
    transition: var(--transition-1);
    --ionicon-stroke-width: 60px;
  }
  
  .faq-card .card-action.active .open,
  .faq-card .card-action .close { display: none; }
  
  .faq-card .card-action .open,
  .faq-card .card-action.active .close { display: block; }
  
  .faq-card .card-content {
    font-size: var(--fs-10);
    padding-inline: 25px;
    max-height: 0;
    overflow: hidden;
    transition: var(--transition-2);
  }
  
  .faq-card .card-content p { margin-block: 15px; }
  
  .faq-card .card-action.active + .card-content { max-height: 500px; }
  
  
  
  
  
  /*-----------------------------------*\
    #FOOTER
  \*-----------------------------------*/
  
  .footer {
    background-color: var(--rich-black-fogra-29-2);
    color: var(--cultured);
  }
  
  .footer-top .container {
    display: grid;
    gap: 40px;
  }
  
  .footer .logo { color: var(--white); }
  
  .footer-text { margin-block: 25px; }
  
  .newsletter-form {
    position: relative;
    max-width: 350px;
  }
  
  .email-field {
    color: var(--white);
    font-size: var(--fs-11);
    border: 1px solid var(--granite-gray);
    padding: 15px;
    border-radius: var(--radius-5);
  }
  
  .footer .form-btn {
    background-color: var(--go-green);
    color: var(--white);
    font-size: 24px;
    position: absolute;
    /* top: 6px; */
    right: 20px;
    bottom: 21px;
    padding: 3px 13px ;

    border-radius: var(--radius-5);
  }
  
  .footer-list-title {
    font-family: var(--ff-cuprum);
    font-size: var(--fs-5);
    font-weight: var(--fw-600);
    line-height: 1.2;
    margin-block-end: 25px;
  }
  
  .footer-link {
    margin-block-start: 15px;
    transition: var(--transition-1);
  }
  
  .footer-link:is(:hover, :focus) { color: var(--go-green); }
  
  .footer-item,
  .social-list {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
  
  .footer-item { margin-block-start: 15px; }
  
  .footer-item > ion-icon {
    flex-shrink: 0;
    color: var(--go-green);
    font-size: 20px;
    margin-block-start: 3px;
  }
  
  .contact-link { transition: var(--transition-1); }
  
  .contact-link:not(.address):is(:hover, :focus) { color: var(--go-green); }
  
  .footer-item:last-child {
    margin-block-start: 25px;
    padding-block-start: 25px;
    border-block-start: 1px solid var(--granite-gray);
  }
  
  .social-link {
    font-size: 14px;
    border: 1px solid var(--granite-gray);
    padding: 1px 5px;
    transition: var(--transition-1);
  }
  
  .social-link:is(:hover, :focus) {
    background-color: var(--go-green);
    border-color: var(--go-green);
  }
  
  .footer-bottom {
    padding-block: 15px;
    border-block-start: 1px solid var(--jet);
 
  }
  
  .copyright {
    font-size: var(--fs-12);
    text-align: center;
    margin-block-end: 15px;
  }
  
  .copyright-link {
    display: inline-block;
    color: var(--go-green);
    font-weight: var(--fw-500);
  }
  
  .footer-bottom-list {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footer-bottom-link {
    font-size: var(--fs-11);
    transition: var(--transition-1);
  }
  
  .footer-bottom-link:is(:hover, :focus) { color: var(--go-green); }
  
  
  
  
  
  /*-----------------------------------*\
    #MEDIA QUERIES 
  \*-----------------------------------*/
  
  /**
   * responsive for large than 575px screen
   */
  
  @media (min-width: 575px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-1: 5.4rem;
  
    }
  
    
  
  
    /**
     * REUSED STYLE
     */
  
    .container {
      max-width: 540px;
      width: 100%;
      margin-inline: auto;
    }
 
  
  
    /**
     * HEADER
     */
  
    .header .container { max-width: unset; }
  
  
  
    /**
     * SERVICE
     */
  
    .service .grid-list { grid-template-columns: 1fr 1fr; }
  
    .service-card { text-align: left; }
  
    .service-card .card-icon { margin-inline: 0; }
    
    .service-card .btn-link { justify-content: flex-start; }

   
  }
  
  @media (min-width: 480px) {
    .form-btn {
      background-color: var(--go-green);
      color: var(--white);
      /* font-size: 24px; */
      position: absolute;
      /* top: 6px; */
      right: 5px !important;
      bottom: 5px !important;
      padding: 3px 13px ;
    }
    .aboutbanner{
      z-index:'2';
       margin-top:'30px';
       position:'absolute';
        color:var(--mint-cream);
    }
    /* style={{zIndex:'2', marginTop:'30px',position:'absolute', color:'var(--mint-cream)'}} */
    
  }
  
  /**
   * responsive for large than 768px screen
   */
  
  @media (min-width: 768px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-1: 7rem;
      --fs-2: 4rem;
      --fs-4: 3.5rem;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 720px; }
  
    .btn { padding: 16px 32px; }
  
    .section-text.text-center {
      max-width: 50ch;
      margin-inline: auto;
    }
  
    .btn-link { --fs-12: 1.4rem; }
  
    .service .grid-list {
      grid-template-columns: 1fr 1fr;
  }
  .gridlist{
   
      grid-template-columns: 1fr 1fr 1fr !important;
  
  }


    /**
     * HERO
     */
  
    .hero-subtitle { --fs-12: 1.4rem; }
  
  
  
    /**
     * FEATURES
     */
  
    .features .grid-list { grid-template-columns: 1fr 1fr; }
  
  
  
    /**
     * FOOTER
     */
  
    .footer-top .container { grid-template-columns: 1fr 1fr; }
  
  }
  
  
  
  
  
  /**
   * responsive for large than 992px screen
   */
  
  @media (min-width: 992px) {
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 960px; }
  
    .img-cover {
      width: 100%;
      height: 100%;
      object-fit: cover;


    }
    .about .btn-group { justify-content: flex-start;
  
    }
    /**
     * HEADER
     */
  
    .nav-open-btn,
    .navbar .wrapper,
    .overlay { display: none; }
  
    .header { padding: 20px; }
  
    .navbar,
    .navbar.active { all: unset; }
  
    .navbar-list {
      display: flex;
      gap: 40px;
    }
  
    .navbar-link {
      color: var(--rich-black-fogra-29-1);
      font-weight: var(--fw-500);
      padding-block: 0;
    }
  
    .header .btn-outline {
      display: block;
      color: var(--go-green);
      font-weight: var(--fw-600);
      text-transform: uppercase;
      border: 1px solid var(--go-green);
      padding: 7px 18px;
      transition: var(--transition-1);
    }
  
    .header .btn-outline:is(:hover, :focus) {
      background-color: var(--go-green);
      color: var(--white);
    }
  
  
  
    /**
     * HERO
     */
  
    .hero { text-align: left; 

   
  }
  .gridabout{
    grid-template-columns: 1fr 1.5fr !important;
  }
  .gridBlogabout{
    grid-template-columns: 1fr 2.6fr !important;
  }

    .hero .container {
      grid-template-columns: 1fr 0.7fr;
      align-items: center;
      gap: 50px;
    }
  .imageholder{
    align-items:flex-start !important;
    
  }
    .hero-subtitle { margin-inline: 25px 0; }
  
    .hero .btn-group { justify-content: flex-start; }
  
  
  
    /**
     * ABOUT
     */
  
    .about .container {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
  
  
  
    /**
     * SERVICE
     */
  
    .service-card {
      display: flex;
      align-items: flex-start;
      gap: 10px;
    }
  
  
  
    /**
     * FEATURES
     */
  
    .features .grid-list { grid-template-columns: repeat(3, 1fr); }
  
  
  
    /**
     * FAQ
     */
  
    .faq .container {
      grid-template-columns: 0.48fr 1fr;
      align-items: flex-start;
    }
  
  
  
    /**
     * FOOTER
     */
  
    .footer-top .container { grid-template-columns: 1fr 0.8fr 0.8fr 1fr; }
  
    .footer-bottom .container {
      /* display: flex; */
      justify-content: space-between;
      align-items: center;
    }
  
    .copyright { margin-block-end: 0; }
  
  }
  
  
  
  
  
  /**
   * responsive for large than 1200px screen
   */
  
  @media (min-width: 1200px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-1: 6.1rem;
      --fs-4: 3.8rem;
  
    }
  
   
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 1140px; }
  
    .btn-link { --fs-12: 1.5rem; }
  
  
  
    /**
     * HERO
     */
  
    .hero {
      min-height: 100vh;
      display: grid;
      align-items: center;
    }
  
  
  
    /**
     * ABOUT
     */
  
    .about .container { grid-template-columns: 1fr 0.7fr; }
  
  
  
    /**
     * FAQ
     */
  
    .faq-card .card-action.active + .card-content { max-height: 200px; }
  
  }