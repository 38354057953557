/* Created by Tivotal */

/* Google Fonts(Poppins) */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");


.containers {
  min-height: 90vh;
  width: 100%;
  background-color:hsl(204, 100%, 98%);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.forms {
  font-size: 1.7rem;
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  z-index: 3;
}

.contact-form {
  background-color: #34BCB2;
  position: relative;
  padding: 30px;
}

.circle {
  position: absolute;
  background: linear-gradient(135deg, transparent 20%, #145592);
  border-radius: 50%;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -20px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form::before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: rgb(27, 92, 121);
  top: 50px;
  left: -12px;
  transform: rotate(45deg);
 
}

form {
  /* padding: 2.3rem 2.2rem; */
  position: relative;
}

.title {
  color: #fff;
  font-weight: 600;
  /* font-size: 1.5rem; */
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: 1px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  font-weight: 400;
  pointer-events: none;
  transition: 0.3s;
  color: #fafafa;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.btns {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size:1.3rem;
  line-height: 1;
  border-radius: 25px;
  color: rgb(27, 92, 121);
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  outline: none;
  width: fit-content;
}

.btns:hover {
  background-color: transparent;
  color: #fff;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: rgb(27, 92, 121);
  
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  font-size: 1.2rem;
  align-items: center;
}

.icon {
  font-size: 25px;
  color: rgb(27, 92, 121);
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #1a70bc, #34BCB2);
  text-align: center;
  color: #fff;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.4s;
}

.social-icons a:hover {
  transform: scale(1.09);
}

.contact-info::before {
  content: "";
  position: absolute;
  width: 110px;
  height: 110px;
  border: 22px solid rgb(27, 92, 121);
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border: 80px solid rgb(27, 92, 121);
  border-radius: 50%;
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;

}

.input-container span::before,
.input-container span::after {
  content: "";
  position: absolute;
  width: 10%;
  height: 5px;
  background-color: rgb(27, 92, 121);
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
}

.input-container span::before {
  left: 50%;
}
.input::placeholder {
  color: white; /* Set the placeholder color to white */
}
.input-container span::after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span::before,
.input-container.focus span::after {
  width: 50%;
  opacity: 1;
}

@media (max-width: 850px) {
  .forms {
    grid-template-columns: 1fr;
  }
  
  }
  /* .contact-info::before {
    bottom: initial;
    top: -72px;
    right: 65px;
    transform: scale(0.95);
  } */

  /* .contact-form::before {
    top: -12px;
    left: initial;
    right: 70px;
  } */

  .big-circle {
    /* bottom: 75%; */
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }


@media (max-width: 480px) {
  .containers {
    margin-top: 60px;
    padding: 1.5rem;
  }
  .circlesizeone{
    margin-top: 95px !important;
    width: 50px !important;
    height: 50px !important;
    margin-right: -20px;
 }
  .circlesizetwo{
    margin-top: 60px !important;
    width: 30px !important;
    height: 30px !important;
    margin-right: -20px;
}
  .contact-info::before {
    display: none;
  }

  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 1.3rem;
  }
/* 
  .title {
    font-size: 1.15rem;
  } */

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    font-size: 20px;
  }

  .inputs {
    padding: 0.45rem 1.2rem;
  }

  .btns {
    padding: 1.25rem 1.6rem;

  }
  .contact-form{
    padding: 10px;
  }
}

@media (min-width: 481px) {
  .circlesizeone{
    margin-top: 115px !important;
    width: 60px !important;
    height: 60px !important;
    margin-right: -20px;
 }
  .circlesizetwo{
    margin-top: 60px !important;
    width: 40px !important;
    height: 40px !important;
    margin-right: -20px;
}
} 
@media (min-width: 668px) {
  .circlesizeone{
    margin-top: 140px !important;
    width: 90px !important;
    height: 90px !important;
    margin-right: -20px;
 }
  .circlesizetwo{
    margin-top: 70px !important;
    width: 60px !important;
    height: 60px !important;
    margin-right: -20px;
}
@media (min-width: 820px) {
.circlesizeone{
  margin-top: 170px !important;
  width: 110px !important;
  height: 110px !important;
  margin-right: -10px;
}
.circlesizetwo{
  margin-top: 70px !important;
  width: 80px !important;
  height: 80px !important;
  margin-right: -20px;
}
}}
@media (max-width: 330px) {
  .circlesizeone{
    margin-top: 70px !important;
    width: 20px !important;
    height: 20px !important;
    margin-right: -20px;
 }
  .circlesizetwo{
    margin-top: 60px !important;
    width: 10px !important;
    height: 10px !important;
    margin-right: -20px;
}
} 
@media (min-width: 1200px) {
  .circlesizeone{
    margin-top: 230px !important;
    width: 160px !important;
    height: 160px !important;
    margin-right: -10px;
 }
  .circlesizetwo{
    margin-top: 100px !important;
    width: 100px !important;
    height: 100px !important;
    margin-right: -20px;
  }
}

